import React, { FC, ReactElement } from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import { ConnectedPage } from '../connectors';
import { KnowledgeArticlesOverview } from '../containers';
import { transformToMetaData } from '../models/Meta';

const KnowledgePage: FC = (): ReactElement => {
    const data = useStaticQuery(graphql`
        query KnowledgePageComponents {
            slices: allPrismicKnowledgeOverview {
                edges {
                    node {
                        dataRaw
                    }
                }
            }
            articles: allPrismicKnowledgeArticle {
                edges {
                    node {
                        id
                        first_publication_date
                        dataRaw
                        uid
                    }
                }
            }
            kennisDownloadableForm: prismicDownloadForm(tags: {eq: "Kennis download form"}) {
                dataRaw
                id
            }
            whitepapers: allPrismicWhitepaper {
                edges {
                    node {
                        id
                        first_publication_date
                        dataRaw
                        uid
                    }
                }
            }
        }
    `);

    const apiData = data.slices.edges[0].node.dataRaw;

    const metaData = apiData.meta.length
        ? transformToMetaData(apiData.meta[0])
        : undefined;

    const articles = data.articles.edges;
    const knowledgeDownloadableForm = data.kennisDownloadableForm.dataRaw.body[0];
    const whitepapers = data.whitepapers.edges;

    return (
        <ConnectedPage metaData={metaData} className="knowledge-page">
            <KnowledgeArticlesOverview
                articles={articles}
                form={knowledgeDownloadableForm}
                whitepapers={whitepapers}
            />
        </ConnectedPage>
    );
};

export default KnowledgePage;
